import api from '../api';
import { types, getParent } from 'mobx-state-tree';

const predefinedCommonTags = new Set(['sortBy']);

const SearchListingStore = types
  .model({
    page: types.maybeNull(types.number),
    feedData: types.frozen(),
    loading: types.optional(types.boolean, false),
    refreshing: types.optional(types.boolean, false),
    more: types.optional(types.boolean, true),
    sort_by: types.optional(types.string, ''),
    tags: types.map(types.string),
    scrollTopOffset: types.optional(types.number, 0)
  })
  .views(self => ({
    get getTags() {
      let values = [];
      self.tags.forEach((value, key) => {
        if (predefinedCommonTags.has(key)) {
          values.push(value);
        }
      });
      return values.filter(item => {
        return item && item !== '';
      });
    }
  }))
  .actions(self => ({
    runInAction: fn => {
      fn();
    },
    setTags(key, value) {
      self.tags.set(key, value);
    },
    setSortBy(type) {
      self.sort_by = type;
    },
    clearSortBy() {
      self.sort_by = '';
    },
    clearTagsAndFilterStore() {
      self.tags = [];
      self.sort_by = '';
    },
    refreshData: async function(sortBy) {
      self.feedData = [];
      self.refreshing = true;
      self.page = 0;
      await self.fetchFeedData(
        sortBy && sortBy.length > 0 ? { sort_by: sortBy } : {}
      );
      self.runInAction(() => {
        self.refreshing = false;
      });
    },
    fetchFeedData: async function(param) {
      getParent(self).uiStore.showLoading();
      self.loading = true;
      const params = Object.assign({}, param);
      params.page = self.page + 1;
      const { data } = await api.topic.search(params);
      self.runInAction(() => {
        if (data && !data.code) {
          const {
            data: data2,
            meta: { current_page: _page, last_page: lastPage }
          } = data;
          self.feedData = [...self.feedData, ...data2];
          self.page = _page;
          self.more = _page < lastPage;
          getParent(self).uiStore.hideLoading();
          self.loading = false;
        }
      });
      return self.feedData;
    },
    async afterCreate() {
      let params = {};
      self.page = 0;
      self.more = true;
      self.feedData = [];
      self.sort_by !== '' && (params.sort_by = self.sort_by);
      self.currentFilter = params;
      // await self.fetchFeedData(params);
    },
    setScroll(offset) {
      self.scrollTopOffset = offset;
    }
  }));

export default SearchListingStore;
