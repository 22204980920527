import React from 'react';
import { Modal } from 'antd-mobile';
import { getImagePopup } from '../util';
import styled from 'styled-components';
import Router from 'next/router';
import { getEnv } from '../utilNoSSR';
import api from '../api';

class PopupAdModal extends React.Component {
  jump = () => {
    const { link, onClose, tuantuan, popupId } = this.props;
    api.Ad.popupClick(popupId);
    if (!link) {
      return null;
    }
    if (tuantuan && getEnv() === 'miniProgram') {
      const wx = require('weixin-js-sdk');
      wx.miniProgram.navigateTo({ url: '/pages/index/tuantuan' });
      onClose();
    }

    let href = '';
    if (link.startsWith('/topic')) {
      href = '/topic/[id]';
    } else if (link.startsWith('/article')) {
      href = '/article/[id]';
    } else if (link.startsWith('/merchant')) {
      href = '/merchant/[merchantId]';
    } else if (link.startsWith('/tuan')) {
      href = '/tuan/[id]';
    }

    Router.push(href, link);
    onClose();
  };

  render() {
    const { show, onClose, popUpAdImage } = this.props;

    return (
      <Styled
        visible={show}
        closable={true}
        maskClosable={true}
        transparent
        onClose={onClose}
      >
        <img
          width="260"
          height="330"
          src={getImagePopup(popUpAdImage)}
          alt={''}
          onClick={this.jump}
        />
      </Styled>
    );
  }
}

const Styled = styled(Modal)`
  text-align: center;
  width: 300px;
  height: 530px;
  .am-modal-content {
    padding: 0 0 0 0 !important;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;

    .am-modal-body {
      padding: 0 0 0 0 !important;
      width: 260px;
      height: 330px;
    }
    .am-modal-close {
      right: 0px;
      top: 20px;
      width: 40px;
      height: 73px;

      .am-modal-close-x {
        width: 100%;
        height: 100%;
        background: url('/static/mini-program_qrcode/wechat_qrcode_close_button.png');
        background-size: cover;
      }
    }
  }
`;

export default PopupAdModal;
