export const ABOUT_GROUP = 0; //关于本群
export const GROUP_FEEDS = 1; //群帖

export const GROUP_HOME_TABS = [{ title: '我的小组' }, { title: '推荐' }];

export const GROUP_CATE = [
  { id: 0, name: 'recommend', title: '热门' },
  { id: 1, name: 'fang', title: '租房' },
  { id: 2, name: 'cheliang', title: '车辆买卖' },
  { id: 3, name: 'gongzuo', title: '求职招聘' },
  { id: 4, name: 'ershou', title: '新货二手' },
  { id: 5, name: 'fuwu', title: '本地服务' },
  { id: 6, name: 'lvyou', title: '旅游门票' },
  { id: 7, name: 'shequ', title: '社区' }
];

export const SELECT_GROUP_LIST_SECTION = ['发布到我的小组', '推荐小组'];
