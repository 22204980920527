import { types, getParent, flow } from 'mobx-state-tree';
import api from '../api';

const FeedStore = types
  .model('FeedStore', {
    data: types.array(types.frozen()),
    cursor: types.string
  })
  .actions(self => ({
    fetchData: flow(function*() {
      getParent(self).uiStore.showLoading();
      const { data } = yield api.topic.socialFeed({
        cursor: self.cursor || null
      });
      if (data && !data.code) {
        self.data = self.data.concat(data.data);
        self.cursor = data.cursor;
      }
      getParent(self).uiStore.hideLoading();

      return self.data.toJS();
    })
  }));

export default FeedStore;
