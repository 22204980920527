import { topic } from '../api';
import { flow, types } from 'mobx-state-tree';
import { TopicModel } from './TopicStore';
import { strMapToObj } from '../util.js';

const SimilarListStore = types
  .model({
    currentFilter: types.map(types.string),
    isLastParams: true,
    nextPageNumber: 1,
    category_name: types.maybeNull(types.string),
    car_make: types.maybeNull(types.string),
    topics: types.array(TopicModel),
    hasMore: true,
    loading: false
  })
  .actions(self => ({
    async afterCreate() {
      let params = {};
      self.initParams();
      self.category_name && (params.category_name = self.category_name);
      self.car_make && (params.make = self.car_make);
      self.currentFilter = params;
      if (self.category_name) {
        await self.fetchSimilarListing(params);
      }
    },
    resetParams() {
      self.currentFilter = {};
      self.isLastParams = true;
      self.nextPageNumber = 1;
      self.category_name = null;
      self.car_make = null;
      self.topics = [];
      self.hasMore = true;
      self.loading = false;
    },

    waitLastParams(value) {
      self.isLastParams = value;
    },

    resetTopics() {
      self.topics = [];
    },

    setCategoryName(value) {
      self.category_name = value;
      self.currentFilter.set('category_name', value);
    },

    setCarMake(value) {
      self.car_make = value;
      self.currentFilter.set('car_make', value);
    },
    runInAction: function(fn) {
      fn();
    },
    fetchSimilarListing: async (param = self.currentFilter) => {
      if (self.hasMore && !self.loading && self.isLastParams) {
        self.loading = true;
        const params = Object.assign({}, strMapToObj(param.toJS()));
        params.page = self.nextPageNumber++;
        const { data } = await topic.search(params);
        if (data) {
          if (data.data) {
            self.runInAction(() => {
              self.topics = [...self.topics, ...data.data];
              self.hasMore = self.nextPageNumber <= data.meta.last_page;
            });
          }
          self.loading = false;
        }
      }
    },

    async loadNextPage() {
      await self.fetchSimilarListing(self.currentFilter);
    },

    initParams() {
      self.nextPageNumber = 1;
      self.hasMore = true;
      self.topics = [];
    }
  }));

export default SimilarListStore;
