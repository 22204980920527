import api from '../api';

import { types, flow } from 'mobx-state-tree';

const Banners = types.model({
  cheliang: types.array(types.frozen()),
  home: types.array(types.frozen()),
  ershou: types.array(types.frozen()),
  fang: types.array(types.frozen()),
  fuwu: types.array(types.frozen()),
  gongzuo: types.array(types.frozen()),
  huodong: types.array(types.frozen()),
  lvyou: types.array(types.frozen()),
  float: types.array(types.frozen())
});

const ConfigStore = types
  .model({
    banners: Banners,
    area: types.frozen(),
    city: types.frozen(),
    currentActivity: types.frozen(),
    category: ''
  })
  .actions(self => {
    const init = flow(function*() {
      const { data } = yield api.initConfig();
      if (data && !data.code) {
        const _city = data.city.map(c => {
          return { ...c, value: c.id, label: c.chinese_name + ' ' + c.name };
        });

        const _area = Object.keys(data.area).reduce((sum, key) => {
          const subarea = data.area[key];
          sum[key] = subarea.map(a => {
            return { ...a, value: a.id, label: a.chinese_name + ' ' + a.name };
          });
          return sum;
        }, {});
        self.currentActivity = data.currentActivity;
        self.banners = data.banners;
        self.area = _area;
        self.city = _city;
        self.category = data.category;
      }
    });
    return { init };
  });

export const ConfigStoreInitialValues = {
  banners: {
    cheliang: [],
    home: [],
    ershou: [],
    fang: [],
    fuwu: [],
    gongzuo: [],
    huodong: [],
    lvyou: [],
    float: []
  }
};

export default ConfigStore;
