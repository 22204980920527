import { types, getParent, flow } from 'mobx-state-tree';
import { userModel } from './UserStore';
import api from '../api';

const ArticleModel = types.model('ArticleModel', {
  id: types.number,
  content: types.string,
  created_at: types.string,
  tags: types.maybeNull(types.array(types.string)),
  title: types.string,
  user: userModel,
  view_count: types.number,
  user_id: types.number,
  images: types.maybeNull(types.array(types.string))
});

const ArticleStore = types
  .model('ArticleStore', {
    article: types.maybeNull(ArticleModel),
    ad: types.frozen(),
    id: types.maybe(types.string)
  })
  .actions(self => ({
    setArticleData(data) {
      self.article = data.article;
      self.ad = data.ad;
    },
    fetchArticle: flow(function* fetchArticle(id) {
      getParent(self).uiStore.showLoading();
      const { data } = yield api.article.get(id);

      if (!data.code) {
        self.setArticleData(data);
      }
      getParent(self).uiStore.hideLoading();
    })
  }));
export default ArticleStore;
