import { action, computed, observable, reaction, runInAction, set } from 'mobx';
import api, { topic } from '../../api';
import { GROUP_CATE } from '../../constants/group';
import { types, getParent, flow } from 'mobx-state-tree';

const GroupProfileInitValue = {
  id: 0,
  name: '',
  group_category_name: null,
  description: '',
  rule: '',
  cover: '',
  avatar: '',
  qr: '',
  category_name: '',
  sub_category_name: '',
  inGroup: false,
  type: -1,
  topicCount: 0,
  userCount: 0
};

const GroupProfileModel = types.model({
  id: types.number,
  name: types.string,
  group_category_name: types.maybeNull(types.string),
  description: types.string,
  rule: types.string,
  cover: types.string,
  avatar: types.string,
  qr: types.string,
  category_name: types.string,
  sub_category_name: types.string,
  inGroup: false,
  type: types.maybe(types.number),
  topicCount: types.maybeNull(types.number),
  userCount: types.maybeNull(types.number)
});

const GroupUserModel = types.model('GroupUserModel', {
  id: types.number,
  name: types.string,
  image: types.maybeNull(types.string)
});

const GroupUserInitValue = {
  id: 0,
  name: '',
  image: ''
};

const nextPageNumber = 2;

const groupDataCache = new Map();

const GroupStore = types
  .model('GroupStore', {
    currentGroupTab: 0,
    groupProfile: types.optional(GroupProfileModel, GroupProfileInitValue),
    myGroups: types.array(GroupProfileModel),
    loadingMyGroups: false,
    groupUsers: types.array(GroupUserModel),
    groupHeaderUsers: types.optional(types.array(GroupUserModel), [
      GroupUserInitValue
    ]),
    groupOwner: types.optional(GroupUserModel, GroupUserInitValue),
    currentGroups: types.array(GroupProfileModel),
    loadingCurrentGroups: false,
    currentCategoryIndex: 0,
    loading: false,
    hasMore: true,
    totalMember: 0,
    recommendGroups: types.array(GroupProfileModel),
    currentTopicCategory: types.maybeNull(types.string),
    currentGroupId: 0,
    myGroupSize: 0,
    currentNewTopicId: 0,
    selectedMap: types.map(types.boolean)
  })
  .views(self => ({
    get currentCategory() {
      return self.groupCategories[self.currentCategoryIndex];
    },
    get myWrapedGroups() {
      if (self.myGroups.length > 3) {
        return self.myGroups
          .slice(0, 4)
          .concat([{ id: -1, type: 1, name: '查看全部' }]); //type = 1 查看更多
      } else if (self.myGroups.length > 0) {
        return self.myGroups.concat([{ id: -2, type: 2, name: '发现更多' }]); //type = 2 发现更多
      }
      return [];
    },
    get getSelectedGroup() {
      const selectedGroupId = [];
      for (var [key, value] of self.selectedMap.entries()) {
        if (value) {
          selectedGroupId.push(key);
        }
      }
      return selectedGroupId;
    },
    get currentCatetoryGroups() {
      return self.myGroups.filter(
        group => group.category_name === self.currentTopicCategory
      );
    },
    get currentRecommendCatetoryGroups() {
      return self.recommendGroups.filter(
        group =>
          group.category_name === self.currentTopicCategory &&
          !self.myGroups.map(myGroup => myGroup.id).includes(group.id)
      );
    }
  }))
  .actions(self => ({
    setCurrentCategory(index) {
      self.currentCategoryIndex = index;
      self.getCurrentGroups();
    },
    setCurrentGroupTab(index) {
      self.currentGroupTab = index;
    },
    clearMyGroups() {
      self.myGroups = [];
    },
    quitGroupAction(groupId) {
      self.groupProfile.inGroup = false;
      self.groupProfile.userCount -= 1;
      getParent(self).userStore.refreshUserInfo();
      self.myGroupSize = self.myGroups.length - 1;
      self.getMyGroups();
      if (self.currentGroups && self.currentGroups.length > 0) {
        let index = -1;
        self.currentGroups.forEach((el, i) => {
          if (el.id === groupId) {
            index = i;
          }
        });
        if (index > -1) {
          self.currentGroups[index].inGroup = false;
          self.currentGroups[index].userCount -= 1;
        }
        //update cache
        groupDataCache.set(self.currentCategory.name, self.currentGroups);
      }
    },
    joinGroupAction(groupId) {
      self.groupProfile.inGroup = true;
      self.groupProfile.userCount += 1;
      if (self.currentGroups && self.currentGroups.length > 0) {
        let index = -1;
        self.currentGroups.forEach((el, i) => {
          if (el.id === groupId) {
            index = i;
          }
        });
        if (index > -1) {
          self.currentGroups[index].inGroup = true;
          self.currentGroups[index].userCount += 1;
        }
      }
      getParent(self).userStore.refreshUserInfo();
      self.myGroupSize = self.myGroups.length + 1;
      self.getMyGroups();
      //update cache
      groupDataCache.set(self.currentCategory.name, self.currentGroups);
    },
    selectGroup(id, selected) {
      self.selectedMap.set(id, selected);
      //console.log(this.getSelectedGroup(this.selectedMap));
    },
    setCurrentTopicCategory(currentTopicCategory) {
      self.currentTopicCategory = currentTopicCategory;
    },
    clearParams() {
      self.hasMore = true;
      self.nextPageNumber = 2;
      self.loading = false;
      self.groupUsers = [];
      self.groupHeaderUsers = [];
    },
    clearSelectGroupListViewData() {
      self.currentTopicCategory = '';
      self.currentGroupId = 0;
    },
    getMyGroups: flow(function*() {
      if (getParent(self).userStore.jwt) {
        self.loadingMyGroups = true;
        const { data } = yield api.group.my();
        self.loadingMyGroups = false;
        if (data && !data.code) {
          Object.assign(self.myGroups, data);
        }
      }
    }),
    getRecommendGroups: flow(function*() {
      self.selectedMap = new Map();
      const { data } = yield api.group.recommend();
      if (data && !data.code) {
        Object.assign(self.recommendGroups, data);
      }
    }),
    getCurrentGroups: flow(function*() {
      self.loadingCurrentGroups = true;
      // data from cache
      const cache = groupDataCache.get(self.currentCategory.name);
      if (cache) {
        self.currentGroups = cache;
        self.loadingCurrentGroups = false;
      } else {
        let data = [];
        if (self.currentCategory.name === 'recommend') {
          ({ data } = yield api.group.recommend(self.currentCategory.name));
        } else {
          ({ data } = yield api.group.category(self.currentCategory.name));
        }
        self.loadingCurrentGroups = false;
        if (data && !data.code) {
          self.currentGroups = data;
          groupDataCache.set(self.currentCategory.name, data);
        } else {
          self.currentGroups = [];
        }
      }
    }),
    getGroupDetailById: flow(function* getGroupDetailById(groupId) {
      const { data } = yield api.group.id(groupId);
      if (data && !data.code) {
        Object.assign(self.groupProfile, data);
        set(self.groupProfile, 'inGroup', data.inGroup || false);
      }
    }),
    getUsersById: flow(function* getUsersById(groupId) {
      const { data } = yield api.group.users(groupId);
      if (data && !data.code) {
        self.totalMember = data.users.total;
        Object.assign(self.groupOwner, data.owner);
        Object.assign(self.groupUsers, data.users.data);

        const groupUserHasImage = data.users.data.filter(user => !!user.image);

        if (groupUserHasImage.length > 6) {
          Object.assign(self.groupHeaderUsers, groupUserHasImage.slice(0, 7));
        } else if (groupUserHasImage > 0) {
          self.groupHeaderUsers = [];
          Object.assign(self.groupHeaderUsers, groupUserHasImage);
        } else {
          self.groupHeaderUsers = [];
        }
      }
    }),
    loadNextPageUsers: flow(function* loadNextPageUsers(groupId) {
      if (self.hasMore && !self.loading) {
        self.loading = true;
        let params = {};
        params.page = self.nextPageNumber;
        const { data } = yield api.group.users(groupId, params);
        if (data && !data.code) {
          self.groupUsers.push(...data.users.data);
          self.loading = false;
          self.nextPageNumber++;
          self.hasMore = self.nextPageNumber <= data.users.last_page;
        }
      }
    }),
    joinGroup: flow(function* joinGroup(groupId) {
      if (!getParent(self).userStore.jwt) {
        getParent(self).uiStore.requireLogin(location.pathname);
        return false;
      }
      const { data } = yield api.group.join(groupId);
      if (data && !data.code) {
        self.joinGroupAction(groupId);
        return true;
      }
      return false;
    }),
    quitGroup: flow(function* quitGroup(groupId) {
      const { data } = yield api.group.leave(groupId);
      if (data && !data.code) {
        self.quitGroupAction(groupId);
      }
    }),
    relevantGroup2Topic: flow(function* relevantGroup2Topic(groupId, topicId) {
      const selectedGroupIds = [...self.getSelectedGroup];
      if (groupId > 0) {
        selectedGroupIds.push(groupId);
      }

      if (selectedGroupIds.length === 0) {
        return true;
      }

      const { data } = yield topic.update(topicId, {
        groups: selectedGroupIds
      });
      const isSuccess = data && !data.code;
      if (isSuccess) {
        self.clearSelectGroupListViewData();
      }
      return isSuccess;
    }),
    afterCreate() {
      self.groupCategories = GROUP_CATE;
    }
  }));

export default GroupStore;
