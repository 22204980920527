import * as process from '../constants/topicNewCarProcess';
import { types } from 'mobx-state-tree';

const TopicNewCarUIStore = types
  .model({
    currentStep: types.optional(types.string, process.STEP_1_AI_SEARCH),
    stepStack: types.optional(types.array(types.string), [])
  })
  .actions(self => ({
    setCurrentStep(step) {
      self.currentStep = step;
    },
    nextStep() {
      if (self.currentStep === process.STEP_7_Contact) {
        return;
      }
      self.stepStack.push(self.currentStep);
      self.currentStep = self.getNextStep();
    },
    previousStep() {
      if (self.stepStack.length > 0) {
        self.currentStep = self.stepStack.pop();
        return true;
      } else {
        return false;
      }
    },
    clear() {
      self.currentStep = process.STEP_1_AI_SEARCH;
      self.stepStack = [];
    },
    getNextStep() {
      switch (self.currentStep) {
        case process.STEP_1_AI_SEARCH: {
          return process.STEP_3_Manual_SEARCH;
        }
        case process.STEP_2_AI_SEARCH_RESULT: {
          return process.STEP_4_PRICE;
        }
        case process.STEP_3_Manual_SEARCH: {
          return process.STEP_4_PRICE;
        }
        case process.STEP_4_PRICE: {
          return process.STEP_5_UPLOAD;
        }
        case process.STEP_5_UPLOAD: {
          return process.STEP_6_EXTRA_INFORMATION;
        }
        case process.STEP_6_EXTRA_INFORMATION: {
          return process.STEP_7_Contact;
        }
        default: {
          return null;
        }
      }
    },
    showResult() {
      self.stepStack.push(self.currentStep);
      self.currentStep = process.STEP_2_AI_SEARCH_RESULT;
    }
  }))
  .views(self => ({
    get currentTitle() {
      switch (self.currentStep) {
        case process.STEP_1_AI_SEARCH: {
          return '检索车牌';
        }
        case process.STEP_2_AI_SEARCH_RESULT: {
          return '检索车牌';
        }
        case process.STEP_3_Manual_SEARCH: {
          return '手动检索';
        }
        case process.STEP_4_PRICE: {
          return '要价';
        }
        case process.STEP_5_UPLOAD: {
          return '上传图片';
        }
        case process.STEP_6_EXTRA_INFORMATION: {
          return '详细描述';
        }
        case process.STEP_7_Contact: {
          return '联系方式';
        }
        default: {
          return '';
        }
      }
    }
  }));

export default TopicNewCarUIStore;

// export default class TopicNewCarUIStore {
//   constructor(rootStore) {
//     this.rootStore = rootStore;
//   }
//   @observable
//   currentStep = process.STEP_1_AI_SEARCH;
//
//   @observable
//   stepStack = [];
//
//   @action.bound
//   setCurrentStep = step => {
//     this.currentStep = step;
//   };
//
//   @action.bound
//   nextStep = () => {
//     if (this.currentStep === process.STEP_7_Contact) {
//       return;
//     }
//     this.stepStack.push(this.currentStep);
//     this.currentStep = this.getNextStep();
//   };
//
//   @action.bound
//   previousStep = () => {
//     if (this.stepStack.length > 0) {
//       this.currentStep = this.stepStack.pop();
//       return true;
//     } else {
//       return false;
//     }
//   };
//
//   @action.bound
//   clear = () => {
//     this.currentStep = process.STEP_1_AI_SEARCH;
//     this.stepStack = [];
//   };
//
//   @computed
//   get currentTitle() {
//     switch (this.currentStep) {
//       case process.STEP_1_AI_SEARCH: {
//         return '检索车牌';
//       }
//       case process.STEP_2_AI_SEARCH_RESULT: {
//         return '检索车牌';
//       }
//       case process.STEP_3_Manual_SEARCH: {
//         return '手动检索';
//       }
//       case process.STEP_4_PRICE: {
//         return '要价';
//       }
//       case process.STEP_5_UPLOAD: {
//         return '上传图片';
//       }
//       case process.STEP_6_EXTRA_INFORMATION: {
//         return '详细描述';
//       }
//       case process.STEP_7_Contact: {
//         return '联系方式';
//       }
//       default: {
//         return '';
//       }
//     }
//   }
//
//   getNextStep = () => {
//     switch (this.currentStep) {
//       case process.STEP_1_AI_SEARCH: {
//         return process.STEP_3_Manual_SEARCH;
//       }
//       case process.STEP_2_AI_SEARCH_RESULT: {
//         return process.STEP_4_PRICE;
//       }
//       case process.STEP_3_Manual_SEARCH: {
//         return process.STEP_4_PRICE;
//       }
//       case process.STEP_4_PRICE: {
//         return process.STEP_5_UPLOAD;
//       }
//       case process.STEP_5_UPLOAD: {
//         return process.STEP_6_EXTRA_INFORMATION;
//       }
//       case process.STEP_6_EXTRA_INFORMATION: {
//         return process.STEP_7_Contact;
//       }
//       default: {
//         return null;
//       }
//     }
//   };
//
//   @action
//   showResult = () => {
//     this.stepStack.push(this.currentStep);
//     this.currentStep = process.STEP_2_AI_SEARCH_RESULT;
//   };
// }
