import { article } from '../api';
import { flow, types } from 'mobx-state-tree';
import { TopicModel } from './TopicStore';
import { strMapToObj } from '../util.js';

const SimilarArticleStore = types
  .model({
    currentFilter: types.map(types.string),
    isLastParams: true,
    nextPageNumber: 1,
    category_name: types.maybeNull(types.string),
    car_make: types.maybeNull(types.string),
    topics: types.array(TopicModel),
    hasMore: true,
    loading: false
  })
  .actions(self => ({
    async afterCreate() {
      let params = {};
      self.initParams();
      self.category_name && (params.category_name = self.category_name);
      self.car_make && (params.make = self.car_make);
      self.currentFilter = params;
      if (self.category_name) {
        await self.fetchSimilarListing(params);
      }
    },
    resetParams() {
      self.currentFilter = {};
      self.isLastParams = true;
      self.nextPageNumber = 1;
      self.category_name = null;
      self.car_make = null;
      self.topics = [];
      self.hasMore = true;
      self.loading = false;
    },

    waitLastParams(value) {
      self.isLastParams = value;
    },

    resetTopics() {
      self.topics = [];
    },

    setCategoryName(value) {
      self.category_name = value;
      self.currentFilter.set('category_name', value);
    },

    setCarMake(value) {
      self.car_make = value;
      self.currentFilter.set('car_make', value);
    },
    runInAction: function(fn) {
      fn();
    },
    fetchSimilarListing: async (param = self.currentFilter) => {
      if (self.hasMore && !self.loading && self.isLastParams) {
        self.loading = true;
        const params = Object.assign({}, strMapToObj(param.toJS()));
        params.page = self.nextPageNumber++;
        const { data } = await article.list(params);
        if (data) {
          if (data.data) {
            self.runInAction(() => {
              const convertedTopic = data.data.map(article => {
                const topic = {};
                const regex = /(<([^>]+)>)/gi;
                topic.id = article.id;
                topic.content = article.content.replace(regex, '');
                topic.title = article.title;
                topic.view_count = article.view_count;
                topic.meta = { price: 0, rent_per_week: 0, salary: 0, type: 0 };
                topic.updated_at = article.updated_at;
                topic.user = article.user;
                if (article.images) {
                  topic.images = article.images;
                }
                return topic;
              });

              self.topics = [...self.topics, ...convertedTopic];
              self.hasMore = self.nextPageNumber <= data.last_page;
            });
          }
          self.loading = false;
        }
      }
    },

    async loadNextPage() {
      await self.fetchSimilarListing(self.currentFilter);
    },

    initParams() {
      self.nextPageNumber = 1;
      self.hasMore = true;
      self.topics = [];
    }
  }));

export default SimilarArticleStore;
