const theme = {
  primaryColor: '#ff8000',
  mobileMaxWidth: '767px',
  redPacketColor: '#d8593f',
  desktopMinWidth: '768px',
  maxWidth: '414px',
  backgroundGrey: 'rgb(249, 250, 251)'
};

export default theme;
