import { runInAction } from 'mobx';
import api from '../api';
import Echo from 'laravel-echo';
import { getFromLocal, setLocalStorage } from '../utilNoSSR.js';
import { guid } from '../util.js';
import CONFIG from '../config';
import { _axios } from '../api.js';
import { types, flow, getParent } from 'mobx-state-tree';
import { MerchantModel } from './MerchantStore';
import mitt from '../common/SMEvent.js';
import { EVENT } from '../common/SMEvent';
import Cookies from 'universal-cookie';

const isServer = typeof window === 'undefined';
export const userModel = types.model('userModel', {
  bak: types.maybeNull(types.string),
  balance: types.maybeNull(types.number),
  brief: types.maybeNull(types.string),
  card_brand: types.maybeNull(types.string),
  card_last_four: types.maybeNull(types.string),
  city_id: types.maybeNull(types.union(types.number, types.string)),
  created_at: types.maybeNull(types.string),
  email: types.maybeNull(types.string),
  email_verified: types.maybeNull(types.boolean),
  email_verify_code: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  image: types.maybeNull(types.string),
  is_vip: types.maybeNull(types.boolean),
  merchant_id: types.maybeNull(types.number),
  merchants: types.optional(types.array(MerchantModel), []),
  mobile: types.maybeNull(types.string),
  my_favourite_count: types.maybeNull(types.number),
  my_topic_count: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  new_user: types.maybeNull(types.boolean),
  promote_coupon: types.maybeNull(types.number),
  roles: types.optional(
    types.array(
      types.model('roleModel', {
        created_at: types.maybeNull(types.string),
        description: types.maybeNull(types.string),
        id: types.maybeNull(types.number),
        name: types.maybeNull(types.string),
        pivot: types.model('pivot', {
          user_id: types.maybeNull(types.number),
          role_id: types.maybeNull(types.number),
          created_at: types.maybeNull(types.string),
          updated_at: types.maybeNull(types.string)
        }),
        updated_at: types.maybeNull(types.string)
      })
    ),
    []
  ),
  status: types.maybeNull(types.number),
  stripe_id: types.maybeNull(types.string),
  telephone: types.maybeNull(types.string),
  trial_ends_at: types.maybeNull(types.string),
  unread_count: types.optional(types.number, 0),
  updated_at: types.maybeNull(types.string),
  user_level: types.maybeNull(
    types.model('userLevelModel', {
      role: types.maybeNull(types.string),
      allowance: types.maybeNull(types.number)
    })
  ),
  watchlist_count: types.maybeNull(types.number),
  coupon_count: types.maybeNull(types.number),
  wechat: types.maybeNull(types.string)
});

const conversationModel = types.model('conversationModel', {
  buyer_id: types.number,
  created_at: types.string,
  id: types.number,
  peer: types.maybeNull(types.frozen()),
  seller_id: types.maybeNull(types.number),
  time: types.string,
  topic: types.maybeNull(types.frozen()),
  topic_id: types.number,
  updated_at: types.maybeNull(types.string),
  messages: types.array(
    types.model('messageModel', {
      content: types.string,
      conversation_id: types.number,
      created_at: types.string,
      from: types.number,
      id: types.number,
      is_read: types.number,
      time: types.string,
      to: types.number,
      updated_at: types.maybeNull(types.string)
    })
  )
});

const MyTopic = types.model('MyTopicModel', {
  auto_renew: types.boolean,
  auto_renew_expire_at: types.maybeNull(types.string),
  canRenew: types.boolean,
  car_value: types.maybeNull(types.number),
  category_name: types.string,
  contact: types.maybeNull(types.string),
  content: types.maybeNull(types.string),
  id: types.number,
  images: types.array(types.string),
  is_deleted: types.boolean,
  meta: types.frozen(),
  renewed_at: types.maybeNull(types.string),
  status: types.number,
  sub_category_name: types.maybeNull(types.string),
  third_category_name: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  updated_at: types.string,
  view_count: types.union(types.string, types.number)
});

const MyModel = types.model('myModel', {
  jwt: types.maybeNull(types.string),
  user: types.maybeNull(userModel),
  conversations: types.optional(types.array(conversationModel), []),
  myTopics: types.maybeNull(types.array(MyTopic)),
  myGroups: types.optional(types.array(types.frozen()), []),
  unReadCommentsMsgCount: types.maybeNull(types.number),
  unReadSystemMsgCount: types.maybeNull(types.number),
  merchants: types.maybeNull(types.array(types.frozen())),
  topicCategory: types.maybeNull(types.string),
  sellerInfo: types.maybeNull(
    types.model({
      topics: types.optional(types.array(types.frozen()), []),
      user: types.frozen()
    })
  ),
  newTopic: types.maybeNull(
    types.model({
      topic_id: types.maybeNull(types.union(types.string, types.number)),
      category_name: types.maybeNull(types.string)
    })
  )
});

export const initUserValue = {
  city_id: 1,
  mobile: '',
  id: 0,
  email: '',
  image: '',
  my_topic_count: 0,
  watchlist_count: 0,
  coupon_count: 0,
  sellerInfo: {},
  name: '',
  user_level: {
    allowance: 0,
    role: ''
  },
  roles: []
};
const UserStore = MyModel.views(self => ({
  get hasUnreadMsg() {
    if (!self.user) return 0;
    const { id } = self.user;
    let count = self.unReadSystemMsgCount + self.unReadCommentsMsgCount;
    self.conversations.forEach(conversation => {
      conversation.messages.forEach(message => {
        if (message.to === id && message.is_read === 0) {
          count++;
        }
      });
    });
    return count;
  },
  get computeCurrentTopicCount() {
    return self.myTopics.filter(t => t.status === 0 || t.status === 10).length;
  },
  get needMobile() {
    return Boolean(self.jwt && self.user.id && !self.user.mobile);
  },
  get alreadyBindMobile() {
    return Boolean(self.jwt && self.user.id && self.user.mobile);
  }
})).actions(self => ({
  updateJWT(jwt) {
    self.jwt = jwt;
    if (!isServer) {
      const cookie = new Cookies();
      cookie.set('jwt', jwt, { expires: new Date(3000, 0, 1), path: '/' });
      _axios.defaults.headers.common['Authorization'] = `Bearer ${jwt}`;
      try {
        localStorage.setItem('jwt', jwt);
      } catch (e) {}
    }
  },
  getUserProfile: flow(function*() {
    const { data } = yield api.my.getProfile();
    if (data && !data.code) {
      self.user = data;
      return true;
    } else {
      return false;
    }
  }),
  initUserStore: flow(function*() {
    const { data } = yield api.my.initUserStore();
    if (data && !data.code) {
      self.user = data.user;
      self.myTopics = data.myTopics;
      self.conversations = data.conversations;
      self.myGroups = data.myGroups;
      self.unReadCommentsMsgCount = data.unreadReplyReceived;
      self.unReadSystemMsgCount = data.unreadSystemMessageNotification;
      getParent(self).eventRegisterStore.setUserInfo(data.user);
      mitt.emit(EVENT.GOT_USER_INFO);
      if (typeof window !== 'undefined') {
        //起websocket监听
        window.Pusher = require('pusher-js');
        window.echo = new Echo({
          broadcaster: 'pusher',
          key: CONFIG.REACT_APP_PUSHER_KEY,
          cluster: 'ap1',
          encrypted: true,
          authEndpoint: CONFIG.backend.base + '/broadcasting/auth',
          auth: {
            headers: {
              Authorization: 'Bearer ' + self.jwt
            }
          }
        });
        window.echo
          .private('rt_' + data.user.id)
          .listen('NewMessageReceived', e => {
            runInAction(() => {
              const index = self.conversations.findIndex(
                c => c.id === e.message.conversation_id
              );
              if (index && self.conversations[index]) {
                const message = self.conversations[index];
                if (message) {
                  message.push(e.message);
                }
              }
            });
          })
          .listen('NewConversationReceived', e => {
            runInAction(() => {
              self.conversations.unshift(e.conversation);
            });
          })
          .listen(
            'NewConversationUpdated',
            flow(function*(e) {
              const { data } = yield api.my.fetchConversation(e.conversationId);
              if (data && !data.code) {
                self.conversations.unshift(data);
              }
            })
          )
          .notification(notification => {
            switch (notification.type) {
              case 'App\\Notifications\\SystemMessageNotification':
                self.unReadSystemMsgCount++;
                break;
              case 'App\\Notifications\\ReplyReceived':
                self.unReadCommentsMsgCount++;
                break;
              default:
                break;
            }
          });
        window.addEventListener('beforeunload', () => {
          if (window.echo) {
            window.echo.leave('rt_' + data.user.id);
          }
        });

        const Sentry = require('@sentry/browser');
        try {
          Sentry.configureScope(scope => {
            const { user } = self;
            if (user) {
              scope.setUser({
                id: user.id,
                email: user.email,
                mobile: user.mobile
              });
            }
          });
        } catch (error) {}
      }

      return true;
    }
    return false;
  }),
  getDeviceId() {
    try {
      if (!getFromLocal('deviceId')) {
        const uuid = guid();
        setLocalStorage('deviceId', uuid);
        return uuid;
      } else {
        return getFromLocal('deviceId');
      }
    } catch (e) {
      return null;
    }
  },
  incrementWatchlistCount() {
    self.user.watchlist_count++;
  },
  decrementWatchlistCount() {
    self.user.watchlist_count--;
  },
  refreshUserLevel: flow(function*() {
    const { data } = yield api.my.refreshUserLevel();
    if (data && !data.code) {
      Object.assign(self.user.user_level, data);
    }
  }),
  bindMobileCheck() {
    return !!self.user.mobile;
  },
  refreshUserInfo: flow(function*() {
    const { data } = yield api.my.initUserStore();
    if (data && !data.code) {
      self.user = data.user;
      self.myTopics = data.myTopics;
      self.conversations = data.conversations;
      self.myGroups = data.myGroups;
      self.unReadCommentsMsgCount = data.unreadReplyReceived;
      self.unReadSystemMsgCount = data.unreadSystemMessageNotification;
      mitt.emit(EVENT.GOT_USER_INFO);
      return true;
    }
    return false;
  }),
  hideConversation: flow(function*(id) {
    if (!id) {
      return false;
    }
    yield api.my.hideConversation({
      conversation_id: id
    });
    self.conversations = self.conversations.filter(item => {
      return item.id !== id;
    });
    return true;
  }),
  getConversation: flow(function*(id) {
    const { data } = yield api.my.getConversation(id);
    if (data && !data.code) {
      const index = self.conversations.findIndex(c => c.id === data.id);
      if (index >= 0) {
        self.conversations[index] = data;
      } else {
        self.conversations.push(data);
      }
      return true;
    }
    return false;
  }),
  updateTopicCategory(category_name) {
    self.topicCategory = category_name;
  },
  async contactSeller(form) {
    const { data } = await api.my.contactSeller(form);
    if (data && !data.code) {
      await self.refreshUserInfo();
      return true;
    }
    return false;
  },
  openMyTopic: flow(function*(id) {
    const { data } = yield api.topic.update(id, { status: 0 });
    if (data && !data.code) {
      self.myTopics[self.myTopics.findIndex(t => t.id === id)].status = 0;
      return true;
    }
    return false;
  }),
  deleteMyTopic: flow(function*(id) {
    const { data } = yield api.topic.delete(id);
    if (data && !data.code) {
      self.myTopics.replace(self.myTopics.filter(t => t.id !== id));
      return true;
    }
    return false;
  }),
  closeMyTopic: flow(function*(id) {
    const { data } = yield api.topic.update(id, { status: 1 });
    if (data && !data.code) {
      self.myTopics[self.myTopics.findIndex(t => t.id === id)].status = 1;
      return true;
    }
    return false;
  }),
  renewMyTopic: flow(function*(id) {
    const { data } = yield api.my.renewTopic(id);
    if (data && !data.code) {
      self.myTopics[self.myTopics.findIndex(t => t.id === id)].canRenew = false;
      return true;
    }
    return false;
  }),
  updateTopic(id, auto_renew, auto_renew_expire_at) {
    const myTopic = self.myTopics[self.myTopics.findIndex(t => t.id === id)];
    if (!myTopic) return;
    myTopic.auto_renew = auto_renew;
    myTopic.auto_renew_expire_at = auto_renew_expire_at;
  },
  async authTokenIssued(data) {
    await self.updateJWT(data.token);
    await self.initUserStore();
  },
  retrieveSellerInfo: flow(function*(userId) {
    const { data } = yield api.account.retrieveSellerInfo(userId);
    if (data && !data.code) {
      self.sellerInfo = data;
      return true;
    }
    return false;
  }),
  login: flow(function*(credentials) {
    const { data } = yield api.account.login(credentials);
    if (data && !data.code) {
      self.updateJWT(data.token);
      yield self.initUserStore();
      return true;
    }
    return false;
  }),
  async simulateLogin(mobile) {
    const { data } = await api.admin.simulateLogin(mobile);
    if (data && !data.code) {
      await self.updateJWT(data.token);
      await self.initUserStore();
      return true;
    }
    return false;
  },
  async wxLogin(code) {
    delete _axios.defaults.headers.common['Authorization'];
    const { data } = await api.account.wxLogin(code);
    if (data && !data.code) {
      runInAction(async () => {
        await self.updateJWT(data.token);
      });
    }
  },
  logout() {
    self.jwt = '';
    if (!isServer) {
      try {
        localStorage.removeItem('jwt');
      } catch (e) {}
    }
    const cookie = new Cookies();
    cookie.remove('jwt', { expires: new Date(3000, 0, 1), path: '/' });
    delete _axios.defaults.headers.common['Authorization'];
    self.user = initUserValue;
    self.myTopics = [];
    self.myGroups = [];
    getParent(self).groupStore.clearMyGroups();
    self.clearUnReadSystemMsg();
    self.clearUnReadCommentsMsg();
  },
  updatePassword: flow(function*(form) {
    const { data } = yield api.password.update(form);
    return !!(data && !data.code);
  }),
  updateProfile: flow(function*(form) {
    const { data } = yield api.profile.update(form);
    if (data && !data.code) {
      self.updateUser(form);
      return true;
    }
    return false;
  }),
  updateUser(form) {
    Object.assign(self.user, form);
  },
  verifyEmail: flow(function*(token) {
    const { data } = yield api.email.verification.do({ token });
    if (data && !data.code) {
      // set(self.user, { email_verified: 1 });
      self.user.email_verified = true;
      return true;
    }

    return false;
  }),
  updateMyTopic: flow(function*(form) {
    const { id } = form;
    const { data } = yield api.topic.update(id, form);
    if (data && !data.code) {
      self.myTopics[self.myTopics.findIndex(t => t.id === id)] = data;
      return true;
    }
    return false;
  }),
  async sendMessage(form) {
    const { data } = await api.my.sendChat(form);
    if (data && !data.code) {
      await self.refreshUserInfo();
      return true;
    }
    return false;
  },
  addedNewPost(post) {
    self.newTopic = {
      topic_id: post.id,
      category_name: post.category_name
    };
    self.myTopics.push(post);
  },
  UpdatePost(id, post) {
    const index = self.myTopics.findIndex(t => t.id === parseInt(id));
    if (index !== -1) {
      self.myTopics[index] = post;
    }
  },
  clearUnReadSystemMsg() {
    self.unReadSystemMsgCount = 0;
  },
  clearUnReadCommentsMsg() {
    self.unReadCommentsMsgCount = 0;
  }
}));

export default UserStore;
