import { flow, types } from 'mobx-state-tree';

const RegisterUIStore = types
  .model({
    selectedCountryCode: '64',
    mobile: '',
    password: '',
    smsCode: ''
  })
  .actions(self => ({
    updateMobile(mobile) {
      self.mobile = mobile;
    },

    updatePassword(password) {
      self.password = password;
    },

    updateSmsCode(smsCode) {
      self.smsCode = smsCode;
    },

    updateCountry(countryCode) {
      self.selectedCountryCode = countryCode;
    }
  }));

export default RegisterUIStore;
