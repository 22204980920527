import api from '../api';
import { types, flow } from 'mobx-state-tree';
import { TopicModel } from './TopicStore';

const WatchListStore = types
  .model({
    topics: types.maybe(types.array(TopicModel))
  })
  .actions(self => ({
    fetchWatchListTopics: flow(function*() {
      const { data } = yield api.topic.list({ type: 'watchlist' });
      if (data && !data.code) {
        const { data: topics } = data;
        self.topics = topics;
        return true;
      }
      return false;
    })
  }));

export default WatchListStore;
