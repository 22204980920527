import Router from 'next/router';
import { getFromLocal, setLocalStorage } from '../utilNoSSR.js';
import { types, getParent } from 'mobx-state-tree';

const MAX_SHOW_PROMPT_TIME = 7;

const UIStore = types
  .model({
    AfterRegisterPath: types.optional(types.string, '/index'),
    showAutoRenewDialog: false,
    isButtonDisabled: true,
    loading: false,
    showWechatMiniProgramModal: true,
    merchantTagIndex: types.number
  })
  .views(self => ({
    get shouldHiddenTips() {
      if (typeof window === 'undefined') {
        return false;
      }

      if (
        (!getFromLocal('showTipsTimes') ||
          getFromLocal('showTipsTimes') <= 5) &&
        new Date().toDateString() !== getFromLocal('showTipsTime')
      ) {
        setLocalStorage('showTipsTime', new Date().toDateString());
        setLocalStorage(
          'showTipsTimes',
          getFromLocal('showTipsTimes')
            ? parseInt(getFromLocal('showTipsTimes')) + 1
            : 1
        );
        return false;
      }
      return true;
    },
    canShowPrompt(page) {
      if (self.promptMessageHistory[page]) {
        const currentCount = self.promptMessageHistory[page];
        return currentCount <= MAX_SHOW_PROMPT_TIME;
      } else {
        return true;
      }
    },
    get promptMessageHistory() {
      if (typeof window === 'undefined') {
        return {};
      }
      let history;
      try {
        history = localStorage.getItem('promptHistory');
      } catch (e) {}
      if (history) {
        return JSON.parse(history);
      } else {
        return {};
      }
    }
  }))
  .actions(self => ({
    setMerchantTagIndex(index) {
      self.merchantTagIndex = index;
    },
    requireLogin(route) {
      if (getParent(self).userStore.jwt) {
        return false;
      }
      self.setAfterRegisterPath(route);
      Router.replace('/login');
      return true;
    },
    setShowAutoRenewDialog(boolean) {
      self.showAutoRenewDialog = boolean;
    },
    hideWechatMiniProgramModal() {
      self.showWechatMiniProgramModal = false;
    },
    showLoading() {
      self.loading = true;
    },
    hideLoading() {
      self.loading = false;
    },
    setButtonDisable() {
      self.isButtonDisabled = true;
    },
    setButtonEnable() {
      self.isButtonDisabled = false;
    },
    setAfterRegisterPath(p) {
      let path = p === '/' ? '/index' : p;
      self.AfterRegisterPath = path;
      if (typeof window === 'undefined') {
        return;
      }
      setLocalStorage('after_register_path', path);
    },
    getAfterRegisterPath(reset = true) {
      if (typeof window === 'undefined') {
        return;
      }
      const path = getFromLocal('after_register_path');
      if (reset) {
        self.setAfterRegisterPath('/');
      }
      return path || '/';
    },
    clearAfterRegisterPath() {
      self.AfterRegisterPath = '/';
    },
    showPrompt(page) {
      if (!page) {
        return;
      }
      if (self.promptMessageHistory[page]) {
        const currentCount = self.promptMessageHistory[page];
        self.promptMessageHistory[page] = currentCount + 1;
      } else {
        self.promptMessageHistory[page] = 1;
      }
      if (typeof window !== 'undefined') {
        try {
          localStorage.setItem(
            'promptHistory',
            JSON.stringify(self.promptMessageHistory)
          );
        } catch (e) {}
      }
    }
  }));

export default UIStore;
